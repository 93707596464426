import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class EventBookingPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container catering-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={8}>
              <h1>Catering</h1>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "booking",
                    displayLabel: "Book Event Space or Offsite Catering",
                    type: "select",
                    options: [
                      { label: "Event Space", value: "Event Space" },
                      { label: "Offsite Catering", value: "Offsite Catering" },
                    ],
                  },
                  {
                    label: "foodRequests",
                    displayLabel: "Food Requests",
                    placeholder: "Food Requests",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "address",
                    displayLabel: "Address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "guest",
                    displayLabel: "Number Of Guests",
                    placeholder: "Number Of Guests",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "eventDate",
                    displayLabel: "Event Date",
                    placeholder: "Event Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "startTime",
                    displayLabel: "Start Time",
                    placeholder: "Start Time",
                    type: "input",
                    inputType: "time",
                    required: true,
                  },
                  {
                    label: "endTime",
                    displayLabel: "End Time",
                    placeholder: "End Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "message",
                    placeholder: "Additional Message",
                    type: "textarea",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
